import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getUserList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/users' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getUserDetails = async (device_id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.apiV2Url + '/users/' + device_id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const saveUserDetails = async (user_id, data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.apiV2Url + '/users/' + user_id, data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const forceSaveUserDetails = async (user_id, data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.put( config.apiV2Url + '/users/' + user_id + '/force', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const createUser = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/users', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const forceCreateUser = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/users/force', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteUser = async (id) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.apiV2Url + '/users/' + id, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const validateSaveUserDetails = async (data) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.apiV2Url + '/users/validate', data, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getUserList,
    getUserDetails,
    saveUserDetails,
    forceSaveUserDetails,
    createUser,
    forceCreateUser,
    deleteUser,
    validateSaveUserDetails
}
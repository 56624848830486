//We should add the version here

import { translate } from "./i18n/customI18nProvider";

//even if it is not used yet
export default {
    version: '4.0.00wf',
    applicationName: 'JM Contactless',
    apiUrl: window.location.origin + "/api",
    apiV2Url: window.location.origin + "/api/v2",
    baseUrl: '',
    baseColor: "#08607e",
    baseColorHover: "#064a62",
    baseColorDark: "#80C0FF",
    successColor: "green",
    successColorDark: "#80FF80",
    dangerColor: "#c00",
    dangerColorDark: "#FF8080",
    // backgroundColor: '#EAE9EF',
    backgroundColor: '#FAFAFA',
    permissions: {
        NONE: {
            value: 0,
            name: ""
        },
        ENTRANCE_CHECKER: {
            value: 5,
            name: translate('custom.entrance_checker')
        },
        SERVER: {
            value: 10,
            name: translate('custom.server')
        },
        CASHIER: {
            value: 20,
            name: translate('custom.cashier')
        },
        ADMIN: {
            value: 30,
            name: translate('custom.admin')
        },
        SUPERADMIN: {
            value: 50,
            name: translate('custom.superadmin')
        }
    },
    entrance_types: {
        ENTRANCE: {
            value: 'entrance',
            // name: "Entrée normale"
            name: translate('custom.normal_entrance')
        },
        UNPAID: {
            value: 'unpaid',
            name: translate('custom.entrance_with_non_refundable_amount')
        },
        CREDIT: {
            value: 'credit',
            name: translate('custom.recharge')
        },
        REPEAT: {
            value: 'repeat_operation',
            name: translate('custom.repeat_operation')
        },
        ADD_MONEY_NON_REFUNDABLE: {
            value: 'add_money_non_refundable',
            name: translate('custom.recharge_non_refundable')
        },
        DEFECT_WRISTBAND: {
            value: 'defect_wristband',
            name: translate('custom.defective_bracelet')
        },
        TRANSFER_WRISTBAND: {
            value: 'transfer_wristband',
            name: translate('custom.transfer_bracelet')
        },
        ADD_ENTRANCE: {
            value: 'add_entrance',
            name: translate('custom.add_entrance')
        },
        REFUND: {
            value: 'refund',
            name: translate('custom.refund')
        },
        FORCED_REFUND: {
            value: 'forced_refund',
            name: translate('custom.forced_refund')
        },
        REPAIR_LINK: {
            value: 'repair_link',
            name: translate('custom.repair_link')
        },
        CHANGE_AGE: {
            value: 'change_age',
            name: translate('custom.change_age')
        }
    },
    ages: {
        LESS_16: {
            value: 0,
            name: translate('custom.everyone')
        },
        LESS_18: {
            value: 16,
            name: translate('custom.age_reverse_map.LESS_18')
        },
        MORE_18: {
            value: 18,
            name: translate('custom.age_reverse_map.MORE_18')
        }
    },
    entrance_ages: {
        LESS_16: {
            value: 0,
            name: translate('custom.age_reverse_map.LESS_16')
        },
        LESS_18: {
            value: 16,
            name: translate('custom.age_reverse_map.LESS_18')
        },
        MORE_18: {
            value: 18,
            name: translate('custom.age_reverse_map.MORE_18')
        }
    },
    ages_reverse_map: {
        0: "age_reverse_map.LESS_16",
        16: "age_reverse_map.LESS_18",
        18: "age_reverse_map.MORE_18"
    },
    transaction_types: {
        DEBIT: 'debit',
        CREDIT: 'credit',
        ENTRANCE: 'entrance',
        ZONE: 'zone',
        CHECKOUT: 'checkout',
        UNPAID: 'unpaid',
        REPEAT: 'repeat_operation',
        ADD_MONEY_NON_REFUNDABLE: 'add_money_non_refundable',
        DEFECT_WRISTBAND: 'defect_wristband',
        TRANSFER_WRISTBAND: 'transfer_wristband',
        ADD_ENTRANCE: 'add_entrance',
        REFUND: 'refund',
        FORCED_REFUND: 'forced_refund',
        TWINT_REFUND: 'twint_refund',
        FORM_REFUND: 'form_refund',
        BALANCE_TRANSFER_FROM_WRISTBAND: 'balance_transfer_from_wristband',
    },
    payment_types: {
        CASH: {
            value: 'cash',
            name: 'Cash'
        },
        CARD: {
            value: 'card',
            name: 'Cartes débit/crédit'
        },
        TWINT: {
            value: 'twint',
            name: 'TWINT'
        },
        TWINT_DIRECT: {
            value: 'twint_direct',
            name: 'TWINT direct'
        }
    },
    languages: {
        EN: {
            value: 'en',
            name: 'English'
        },
        FR: {
            value: 'fr',
            name: 'Français'
        },
        DE: {
            value: 'de',
            name: 'Deutsch'
        },
    },
    round: {
        // "0.01" : {
        //     value: 0.01,
        //     name: "0.01"
        // },
        "0.05" : {
            value: 0.05,
            name: "0.05"
        },
        "0.10" : {
            value: 0.10,
            name: "0.10"
        },
        "0.5" : {
            value: 0.5,
            name: "0.5"
        },
        "1" : {
            value: 1,
            name: "1"
        }
    },
    channel_types: {
        WEB: {
            value: 'web',
            name: 'Web',
            short: 'W'
        },
        APP: {
            value: 'app',
            name: 'App',
            short: 'A'
        }
    },
    ticket_category_ids: {
        GENERAL_TICKET : {
            value: 1292105,
            name: "1292105"
        },
        TEST_TICKET : {
            value: 123456,
            name: "123456"
        }
    },
    consumption_colors: [
        {id: 'purple', name: translate('custom.colors.purple'), colorCode: '#a269ff80'},
        {id: 'red', name: translate('custom.colors.red'), colorCode: '#ff5436cc'},
        {id: 'orange', name: translate('custom.colors.orange'), colorCode: '#ffcd81'},
        {id: 'gray', name: translate('custom.colors.gray'), colorCode: '#a6a4a3'},
        {id: 'green', name: translate('custom.colors.green'), colorCode: '#6dc778'},
    ],
    consumption_types: {
        QUANTITY: 'quantity',
        INPUT: 'input'
    },
    amounts_to_display: ["10", "20", "30", "40", "50", "60", "70", "100"],
    maxLoadAmount: 1000,
    valid_characters_regex: /[^a-zA-Z0-9.,;:'+\-/()?\\[\]{}`´~!"#%&<>÷=@_$£àáâäçèéêëìíîïñòóôöùúûüýßÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖÙÚÛÜÑ\s\n\r]/g,
    TWINT_PAYMENTS_ORDER_STATUS: {
        IN_PROGRESS: 'IN_PROGRESS',
        SUCCESS: 'SUCCESS',
        FAILURE: 'FAILURE',
    },
    TWINT_PAYMENTS_ORDER_REASON: {
        ORDER_PENDING: 'ORDER_PENDING',
        ORDER_CONFIRMATION_PENDING: 'ORDER_CONFIRMATION_PENDING',
        ORDER_OK: 'ORDER_OK'
    },
    TWINT_PAYMENTS_PAIRING_STATUS: {
        NO_PAIRING: 'NO_PAIRING'
    },
    firebaseConfig: {
        apiKey: "AIzaSyAICERAyTUbQ1MM8zI6tdzfVTbzmxuIE_w",
        authDomain: "contactless-wristband.firebaseapp.com",
        projectId: "contactless-wristband",
        storageBucket: "contactless-wristband.appspot.com",
        messagingSenderId: "14651464370",
        appId: "1:14651464370:web:a16da15039a775a87ec7ab",
        measurementId: "G-KZGQZFKX5Z"
    },
    firebaseVapidKey: "BJNafHDN-Mrh7fsyH-NMeHw-eyOFpl55nm1kshMw4iVZRKAZ8s3r7JsWemPMZsab14yNX39PAyqUAs2Dj5IKkV4",
    amounts_to_display_choices: [
        {id: "10", name: "10"},
        {id: "20", name: "20"},
        {id: "30", name: "30"},
        {id: "40", name: "40"},
        {id: "50", name: "50"},
        {id: "60", name: "60"},
        {id: "70", name: "70"},
        {id: "100", name: "100"}
    ],
    donation_amounts: [1, 2, 3],
    printer_types: [
        {id: "epson", name: "EPSON"},
        {id: "tanca", name: "TANCA"},
        {id: "star", name: "STAR"},
        {id: "daruma", name: "DARUMA"},
        {id: "brother", name: "BROTHER"}
    ],
    device_statuses: [
        {key: '', name: ''},
        {key: 'active', name: translate('custom.active')},
        {key: 'to_avoid', name: translate('custom.to_avoid')},
        {key: 'under_repair', name: translate('custom.under_repair')},
        {key: 'loaned', name: translate('custom.loaned')},
        {key: 'not_available', name: translate('custom.not_available')},
        {key: 'inactive', name: translate('custom.inactive')}
    ],
    device_types: {
        SMARTPHONE: {
            key: 'smartphone',
            name: translate('custom.smartphone')
        },
        MODEM: {
            key: 'modem',
            name: translate('custom.modem')
        },
        TERMINAL_POS: {
            key: 'terminal_pos',
            name: translate('custom.terminal_pos')
        }
    },
    device_brands: {
        modem: [
            {key: '', name: ''},
            {key: 'huawei_modem', name: 'Huawei Modem'}
        ],
        terminal_pos: [
            {key: '', name: ''},
            {key: 'my_pos', name: 'MyPOS'}
        ],
        smartphone: [
            {key: '', name: ''},
            {key: 'Huawei P Smart 2018', name: 'Huawei P Smart 2018'},
            {key: 'Huawei P Smart 2019', name: 'Huawei P Smart 2019'},
            {key: 'Huawei Y6p', name: 'Huawei Y6p'},
            {key: 'Nokia', name: 'Nokia'},
            {key: 'Nokia 7.1', name: 'Nokia 7.1'},
            {key: 'Nokia G21', name: 'Nokia G21'},
            {key: 'Nokia G22', name: 'Nokia G22'},
            {key: 'Oppo A54s', name: 'Oppo A54s'},
            {key: 'Redmi', name: 'Redmi'},
        ]
    },
    device_operators: [
        {key: '', name: ''},
        {key: 'digital republic', name: 'digital republic'},
        {key: 'Salt', name: 'Salt'},
        {key: 'Sunrise', name: 'Sunrise'},
        {key: 'Swisscom', name: 'Swisscom'},
        {key: 'Wingo', name: 'Wingo'},
    ],
    refund_status: {
        ERROR: 'error',
        WARNING: 'warning',
        REFUNDING: 'refunding',
        SUCCESS: 'success',
        CANCELLED: 'cancelled'
    },
    deposit_types: [
        {id: "PLATE", name: translate('custom.DEPOSIT_TYPES.PLATE')},
        {id: "BOWL", name: translate('custom.DEPOSIT_TYPES.BOWL')},
        {id: "CUP", name: translate('custom.DEPOSIT_TYPES.CUP')},
        {id: "WINE_CUP", name: translate('custom.DEPOSIT_TYPES.WINE_CUP')},
        {id: "COFFEE_CUP", name: translate('custom.DEPOSIT_TYPES.COFFEE_CUP')},
        {id: "MUG", name: translate('custom.DEPOSIT_TYPES.MUG')},
        {id: "CUTLERY", name: translate('custom.DEPOSIT_TYPES.CUTLERY')}
    ],
    token_permissions: [
        { id: 'deposit_return', name: translate('custom.deposit_return') }
    ]
};
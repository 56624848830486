import react from 'react';
import V2Layout from './Layout';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@mui/material';
import StyledTableRow from '../../components/v2/common/table/StyledTableRow';
import { translate } from '../../i18n/customI18nProvider';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import config from '../../config';
import SearchIcon from '@mui/icons-material/Search';
import { deleteUser, getUserList } from '../../services/user.service';
import { getDeviceType, getRoleFromRight, getRoles, getUrlParam, removeUrlParam, setUrlParam } from '../../services/common.service';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getEventList } from '../../services/event.service';
import { getBarList } from '../../services/bar.service';
import { getRight } from '../../services/storage';

const tableHeader = [
    {
        id: 'event',
        key: 'event',
        align: 'left',
        disablePadding: false,
        label: translate('custom.event'),
        sortable: true
    },
    {
        id: 'bar',
        key: 'bar',
        align: 'left',
        disablePadding: false,
        label: translate('custom.point_of_sale'),
        sortable: true
    },
    {
        id: 'email',
        key: 'email',
        align: 'left',
        disablePadding: false,
        label: translate('custom.username'),
        sortable: true
    },
    {
        id: 'right',
        key: 'right',
        align: 'left',
        disablePadding: false,
        label: translate('custom.permission'),
        sortable: true
    },
    {
        id: 'smartphones',
        key: 'smartphones',
        align: 'left',
        disablePadding: false,
        label: translate('custom.smartphones'),
        sortable: false
    },
    {
        id: 'actions',
        key: 'actions',
        align: 'left',  
        disablePadding: false,
        label: translate('custom.actions'),
        sortable: false
    }
]

class Users extends react.Component {

    constructor(props) {
        super(props);

        this.state = {
            device_type: getDeviceType(),
            current_right: getRight(),
            users: [],
            count: 0,
            skip: 0,
            limit: 50,
            limitOptions: [10, 50, 100, 250, 500],
            events: [],
            bars: [],
            roles: [],
            // Filter
            ...this.getUrlParams()
        };

        // push state
        window.history.pushState({}, '', window.location.href);
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    getUrlParams() {
        return {
            show_filter: getUrlParam('show_filter', false),
            event: getUrlParam('event'),
            bar: getUrlParam('bar'),
            right: getUrlParam('right', ''),
            sort_key: getUrlParam('sort_key', ''),
            sort_order: getUrlParam('sort_order', ''),
            search: getUrlParam('search', ''),
            smartphones: getUrlParam('smartphones', ''),
            archived: getUrlParam('archived', false),
            event_ended: getUrlParam('event_ended', false),
            deleted_only: getUrlParam('deleted_only', false),
            with_deleted: getUrlParam('with_deleted', false)
        };
    }

    // setUrlParam(key, value) {
    //     let url = new URL(window.location.href);
    //     url.searchParams.set(key, JSON.stringify(value));
    //     window.history.replaceState({}, '', url);
    // }

    // removeUrlParam(key) {
    //     let url = new URL(window.location.href);
    //     url.searchParams.delete(key);
    //     window.history.replaceState({}, '', url);
    // }

    // getUrlParam(key, defaultValue = null) {
    //     let url = new URL(window.location.href);
    //     let value = url.searchParams.get(key);
    //     if (value) {
    //         return JSON.parse(value);
    //     }
    //     return defaultValue;
    // }

    async componentDidMount() {
        this.getUsers();
        this.setEvents();
        this.setBars();
        this.setRoles();

        // On URL state change
        window.onpopstate = async () => {
            this.setState(this.getUrlParams());
            this.getUsers();
        }
    }

    componentWillUnmount() {
        window.onpopstate = () => { }
    }

    async getUsers() {
        let options = {
            skip: this.state.skip,
            limit: this.state.limit
        }
        
        if (this.state.sort_key) {
            options.sort_key = this.state.sort_key;
            options.sort_order = this.state.sort_order;
        }

        if (this.state.search) {
            options.search = this.state.search;
        }

        if (this.state.event) {
            options.event_id = this.state.event.id;
        }

        if (this.state.bar) {
            options.bar_id = this.state.bar.id;
        }

        if (this.state.right) {
            options.right = this.state.right.value;
        }

        if (this.state.smartphones) {
            options.smartphones = this.state.smartphones;
        }

        if (this.state.archived) {
            options.archived = this.state.archived;
        }

        if (this.state.event_ended) {
            options.event_ended = this.state.event_ended;
        }

        if (this.state.deleted_only) {
            options.deleted_only = this.state.deleted_only;
        }

        if (this.state.with_deleted) {
            options.with_deleted = this.state.with_deleted;
        }

        let users = await getUserList(options);
        this.setState({
            users: users.users,
            count: users.count
        });
    }

    async setEvents() {
        let events = await getEventList({
            minimal: true
        });

        if (events.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: events.error_code ? convertMessageCodeToMessage(events.error_code) : events.error
            });
            return;
        }

        let eventsArray = events.events.map((event) => {
            return {
                id: event._id,
                label: event.name
            }
        });

        await this.setStateAsync({ events: eventsArray });
    }

    async setBars() {

        let filter = {
            minimal: true
        }

        if (this.state.event) {
            filter.event_id = this.state.event.id;
        }

        let bars = await getBarList(filter);

        if (bars.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: bars.error_code ? convertMessageCodeToMessage(bars.error_code) : bars.error
            });
            return;
        }

        let barsArray = bars.bars.map((bar) => {
            return {
                id: bar._id,
                label: bar.name
            }
        });

        await this.setStateAsync({ bars: barsArray });
    }

    async setRoles() {
        let roles = getRoles();
        await this.setStateAsync({ roles });
    }


    async deleteUserFromId(id) {
        let c = await Swal.fire({
            title: translate('custom.are_you_sure'),
            text: translate('custom.do_you_want_to_delete_this_user'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: translate('custom.yes'),
            cancelButtonText: translate('custom.no'),
            confirmButtonColor: config.baseColor
        });
        if (c.isConfirmed) {
            let response = await deleteUser(id);
            if (response.error) {
                Swal.fire({
                    title: translate('custom.error'),
                    text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error,
                    icon: 'error',
                    confirmButtonColor: config.baseColor
                });
                return;
            }

            Swal.fire({
                title: translate('custom.success'),
                text: response.message_code ? convertMessageCodeToMessage(response.message_code) : response.message,
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false
            });

            this.getUsers();
        }
    }

    render() {
        return (
            <V2Layout
                currentMenu='users'
            >
                {(this.state.current_right < config.permissions.SUPERADMIN.value) ? (
                    <Grid container spacing={2} style={{marginBottom: '10px', justifyContent: 'flex-end'}}>
                        <Grid item>
                            <Button variant='contained' style={{textTransform: 'none'}}
                                onClick={() => {
                                    this.props.history.push('/v2/admin/users/add');
                                }}
                            >
                                + {translate('custom.user')}
                            </Button>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid container style={{marginBottom: '10px'}}>
                    <Card style={{width: '100%', padding: '20px'}}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="search-input">{translate('custom.search')}</InputLabel>
                                    <OutlinedInput
                                        id="search-input"
                                        startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                                        label={translate('custom.search')}
                                        value={this.state.search}
                                        onChange={async (e) => {
                                            await this.setState({search: e.target.value});
                                            setUrlParam('search', e.target.value);
                                            this.getUsers();
                                        }}
                                    />
                                </FormControl>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="event-label"
                                    options={this.state.events}
                                    value={this.state.event}
                                    renderInput={(params) => <TextField {...params} label={translate('custom.event')} />}
                                    getOptionKey={(option) => option.id}
                                    onChange={async (e, value) => {
                                        await this.setStateAsync({ event: value, bar: null });
                                        await this.setBars();
                                        setUrlParam('event', value);
                                        removeUrlParam('bar');
                                        this.getUsers();
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="smartphone-input">{translate('custom.smartphone')}</InputLabel>
                                    <OutlinedInput
                                        id="smartphone-input"
                                        label={translate('custom.smartphone')}
                                        value={this.state.smartphones}
                                        onChange={async (e) => {
                                            await this.setStateAsync({smartphones: e.target.value});
                                            setUrlParam('smartphones', e.target.value);
                                            this.getUsers();
                                        }}
                                    />
                                </FormControl>
                                <Button aria-label="filter"
                                    variant={this.state.show_filter ? 'contained' : 'outlined'}
                                    onClick={() => {
                                        this.setState({show_filter: !this.state.show_filter});
                                        setUrlParam('show_filter', !this.state.show_filter);
                                    }}
                                >
                                    <FilterAltIcon />
                                </Button>
                            </Stack>
                        </Grid>
                        {(this.state.show_filter) ? (
                            <Grid container spacing={2} sx={{mt: 1}}>
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Autocomplete
                                        disablePortal
                                        id="event-label"
                                        options={this.state.events}
                                        value={this.state.event}
                                        renderInput={(params) => <TextField {...params} label={translate('custom.event')} />}
                                        getOptionKey={(option) => option.id}
                                        onChange={async (e, value) => {
                                            await this.setStateAsync({ event: value, bar: null });
                                            await this.setBars();
                                            setUrlParam('event', value);
                                            removeUrlParam('bar');
                                            this.getUsers();
                                        }}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Autocomplete
                                        disablePortal
                                        id="bar-label"
                                        options={this.state.bars}
                                        value={this.state.bar}
                                        renderInput={(params) => <TextField {...params} label={translate('custom.point_of_sale')} />}
                                        getOptionKey={(option) => option.id}
                                        onChange={async (e, value) => {
                                            await this.setStateAsync({ bar: value });
                                            setUrlParam('bar', value);
                                            this.getUsers();
                                        }}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6} lg={4} xl={3}>

                                    <Autocomplete
                                        disablePortal
                                        id="right-label"
                                        options={this.state.roles}
                                        value={this.state.right}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={translate('custom.permission')}
                                            />
                                        }
                                        getOptionKey={(option) => option.value}
                                        getOptionLabel={(option) => option.name ? option.name : ''}
                                        onChange={async (e, value) => {
                                            await this.setStateAsync({ right: value });
                                            setUrlParam('right', value);
                                            await this.getUsers();
                                        }}
                                    />

                                </Grid>

                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="smartphone-input">{translate('custom.smartphone')}</InputLabel>
                                        <OutlinedInput
                                            id="smartphone-input"
                                            label={translate('custom.smartphone')}
                                            value={this.state.smartphones}
                                            onChange={async (e) => {
                                                await this.setStateAsync({smartphones: e.target.value});
                                                setUrlParam('smartphones', e.target.value);
                                                this.getUsers();
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}></Grid>

                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.archived}
                                                    onChange={async (e) => {
                                                        await this.setStateAsync({ archived: e.target.checked });
                                                        setUrlParam('archived', e.target.checked);
                                                        this.getUsers();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.archived')}
                                        />
                                    </FormGroup>
                                </Grid>
                                
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.event_ended}
                                                    onChange={async (e) => {
                                                        await this.setStateAsync({ event_ended: e.target.checked });
                                                        setUrlParam('event_ended', e.target.checked);
                                                        this.getUsers();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.event_ended')}
                                        />
                                    </FormGroup>
                                </Grid>
                                
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.with_deleted}
                                                    onChange={async (e) => {
                                                        let deleted_only = (e.target.checked) ? false : this.state.deleted_only;
                                                        await this.setStateAsync({ with_deleted: e.target.checked, deleted_only });
                                                        setUrlParam('with_deleted', e.target.checked);
                                                        setUrlParam('deleted_only', deleted_only);
                                                        this.getUsers();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.with_deleted')}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.deleted_only}
                                                    onChange={async (e) => {
                                                        let with_deleted = (e.target.checked) ? false : this.state.with_deleted;
                                                        await this.setStateAsync({ deleted_only: e.target.checked, with_deleted });
                                                        setUrlParam('deleted_only', e.target.checked);
                                                        setUrlParam('with_deleted', with_deleted);
                                                        this.getUsers();
                                                    }}
                                                />
                                            }
                                            label={translate('custom.deleted_only')}
                                        />
                                    </FormGroup>
                                </Grid>

                            </Grid>
                        ) : null}
                    </Card>
                </Grid>


                {/* <TableContainer component={Card} style={{maxHeight: '70vh', overflow: 'auto'}}> */}
                <TableContainer component={Card}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((headCell) => (
                                    <TableCell
                                        key={headCell.key}
                                        align={headCell.align ? headCell.align : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={(this.state.sort_key === headCell.key) ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : false}
                                    >
                                        {(headCell.sortable) ? (
                                            <TableSortLabel
                                                active={this.state.sort_key === headCell.key}
                                                direction={this.state.sort_key === headCell.key ? ((this.state.sort_order == 'ASC') ? 'asc' : 'desc') : 'asc'}
                                                onClick={async () => {
                                                    await this.setState({
                                                        sort_key: headCell.key,
                                                        sort_order: this.state.sort_key === headCell.key ? (this.state.sort_order === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
                                                    })
                                                    this.getUsers();
                                                }}
                                            >
                                                {headCell.label}
                                                {this.state.sort_key === headCell.key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {this.state.sort_order === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            <>
                                                {headCell.label}
                                            </>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.users.map((row) => (
                                <StyledTableRow key={row._id} hover
                                    onDoubleClick={() => {
                                        this.props.history.push('/v2/admin/users/' + row._id);
                                    }}
                                    onClick={() => {
                                        if (this.state.device_type === 'mobile') {
                                            this.props.history.push('/v2/admin/users/' + row._id);
                                        }
                                    }}
                                    style={(this.state.device_type === 'mobile') ? {cursor: 'pointer'} : {}}
                                >
                                    <TableCell>{row.event_id?.name}</TableCell>
                                    <TableCell>{row.bar_id?.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{getRoleFromRight(row.right).name}</TableCell>
                                    <TableCell>
                                        {row.smartphones.map((smartphone) => {
                                            return (
                                                <Chip key={smartphone} label={smartphone} />
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton size='small' onClick={() => {
                                            this.props.history.push('/v2/admin/users/' + row._id);
                                        }}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton size='small' color='error' onClick={() => {
                                            this.deleteUserFromId(row._id);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12}> 
                        <TablePagination
                            component="div"
                            count={this.state.count}
                            page={this.state.skip / this.state.limit}
                            onPageChange={async (event, page) => {
                                await this.setState({
                                    skip: page * this.state.limit
                                })
                                this.getUsers();
                            }}
                            rowsPerPage={this.state.limit}
                            onRowsPerPageChange={async (event) => {
                                await this.setState({
                                    limit: event.target.value
                                })
                                this.getUsers();
                            }}
                            rowsPerPageOptions={this.state.limitOptions}
                        />
                    </Grid>
                </Grid>
            </V2Layout>
        )
    }
}

export default Users;
